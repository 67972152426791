<template>
  <div >
    <el-dialog title="编辑素材" v-model="dialogVisible" width="600px">
        <div class="container">
            <div class="wrap">
                <el-form
                    :model="ruleForm"
                    :rules="rules"
                    ref="ruleFormRef"
                    label-width="60px"
                    class="ruleForm"
                >
                <el-form-item label="编号">
                    <el-input
                        v-model="ruleForm.data.ID"
                        placeholder="请填写"
                        :disabled="true"
                        show-word-limit
                    ></el-input>
                </el-form-item>
                    <el-form-item label="标题" prop="data.name">
                        <el-input
                            v-model="ruleForm.data.name"
                            placeholder="请填写名称"
                            maxlength="24"
                            show-word-limit
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="描述" prop="data.desc">
                        <el-input
                            v-model="ruleForm.data.desc"
                            placeholder="请填写描述"
                            maxlength="24"
                            show-word-limit
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="图片" prop="data.url">
                        <el-upload
                            class="uploader"
                            action="no"
                            :show-file-list="false"
                            :on-change="imgSaveToUrl"
                            :auto-upload="false"
                            accept="image/jpeg,image/jpg,image/png,image/gif"
                            :before-upload="beforeAvatarUpload"
                        >
                            <img v-if="ruleForm.data.url" :src="ruleForm.data.url" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="链接" prop="data.href">
                        <el-input v-model="ruleForm.data.href" placeholder="请填写链接"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="line"></div>

            <div class="wrap">
            <p class="preview">预览效果</p>
            <div class="preview-cell">
                <div class="cell-left">
                <p>{{ ruleForm.data.name }}</p>
                <p class="cell-desc">{{ ruleForm.data.desc }}</p>
                </div>
                <img :src="ruleForm.data.url" />
            </div>
            </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitAction('ruleForm')">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { onMounted, reactive, ref, unref } from "vue";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import tools from "@/assets/js/tools";
import {UpLoadImageOrVideo,UpdateImageOrVideo } from "@/helper/material.js";
import _ from "lodash";

export default {
  name: "EditTextCop",
  components: {
  },
  setup(prop, context) {
    const dialogVisible=ref(false)
    const ruleFormRef = ref(null);
    const ruleForm = reactive({
      data: {
        ID: 0,
        url:"",
        desc:"",
        href:'',
        name:''
      },
    });
    const rules = reactive({
      data: {
        url:[{ required: true, message: "请选择图片", trigger: "change" }],
        desc:[{ required: true, message: "请填写描述", trigger: "blur" }],
        href:[{ required: true, message: "请选择链接地址", trigger: "blur" }],
        name: [
            { required: true, message: "请填写名称", trigger: "blur" },
            { min: 1, max: 24, message: "长度在 1 到 24 个字符", trigger: "blur" },
        ],
      }
    });

    onMounted(() => {});

    function initCop(item){
        dialogVisible.value = true
        resetData()
        loadData(item)
    }
    
    function resetData(){
        ruleForm.data.name = ''
        ruleForm.data.url=""
        ruleForm.data.desc=""
        ruleForm.data.href=''
    }

    function loadData(item){
        ruleForm.data.ID = item.ID 
        ruleForm.data.name = item.MsgTitle
        ruleForm.data.url= item.MsgContent
        ruleForm.data.desc= item.MsgDesc
        ruleForm.data.href= item.MsgHref
    }

    //检测图片
    function beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 4;
      const isImage= file.type == 'image/png' || 'image/jpg'|| 'image/jpeg' || 'image/gif';
      if (!isLt2M) {
        ElMessage({
          type: "error",
          message: "上传图片大小不能超过 4MB!",
        });
      }
      if (!isImage) {
        ElMessage({
            type: "error",
            message: "上传图片只支持png/jpeg/jpg/gif!",
        });
      }
      return isLt2M&&isImage;
    }

    //非自动上传
    function imgSaveToUrl(event) {
      const formData = new FormData();
      formData.append("file", event.raw);
      UpLoadImageOrVideo(2002,formData).then((res) => {
        ruleForm.data.url = res.OSSUrl;
        ElMessage.success({
          message: "上传成功",
          type: "success",
        });
      });
    }
    /*********************************点击事件******************************************/
     //确定表单
    const submitAction = _.debounce(
      async () => {
        const form = unref(ruleFormRef);
        if (!form) return;
        try {
            await form.validate();
            let param = {
                Mid: ruleForm.data.ID,
                Cover:ruleForm.data.url,
                Title:ruleForm.data.name,
                Desc:ruleForm.data.desc,
                Href:ruleForm.data.href,
            };
            UpdateImageOrVideo(param).then((res) => {
                ElMessage({
                    type: "success",
                    message: "提交成功",
                });
                dialogVisible.value = false;
                context.emit("onConfirm");
            });
        } catch (error) {
          console.log(error);
        }
      },
      1000,
      {
        leading: true, //在延迟开始前立即调用事件
        trailing: false, //在延时结束后不调用,保证事件只被触发一次
      }
    );


    return {
        dialogVisible,
        initCop,
        ruleForm,
        rules,
        submitAction,
        ruleFormRef,
        imgSaveToUrl,
        beforeAvatarUpload
    };
  },
};
</script>
<style lang='scss' scoped>
.container {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  .wrap {
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .uploader {
      width: 40px;
      height: 40px;
      border: 1px solid #ccc;
      border-radius: 3px;
      box-sizing: border-box;
      overflow: hidden;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .line {
    height: 100%;
    width: 2px;
    background-color: #ccc;
    position: absolute;
    top: 0;
    left: 50%;
  }

  .foot-wrap {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}

.preview-cell {
  position: relative;
  margin-left: 15px;
  width: 240px;
  height: 100px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border: 1px solid #ccc;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  img {
    width: 80px;
    height: 80px;
    margin: 0 10px;
  }

  .cell-left {
    height: 80px;
    width: 140px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
    color: #000;
    padding: 5px;
    box-sizing: border-box;

    .cell-desc {
      font-size: 12px;
      color: #ccc;
    }
    p {
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 20px;
    }
  }
}
</style> 
