<template>
  <div>
    <div class="table-box">
      <div class="card-box">
        <div class="list">
          <div
            class="content-item mini-item"
            v-for="(item, index) in TabList"
            :key="index"
          >
          <div class="mini-header">
            <img class="mini_btn" src="../../assets/imgs/chat_btn_mini.png" alt="">
            <div :title="item.MsgTitle" class="title" style="line-height: 38px">
              {{ item.MsgTitle }}
            </div>
          </div>
            <div class="msg_content">{{item.MsgContent}}</div>
            <p class="cell-desc" :title="item.MsgDesc">{{ item.MsgDesc }}</p>
            <img class="imgs" :src="item.MsgHref" />
            <p  class="name" style="border-top: 1px solid #ccc; background: rgba(0, 0, 0, 0.5)">【{{item.ID}}】{{item.Remark}}</p>
            <div class="edit-list" style="margin-top: 0">
              <el-checkbox v-model="item.checked" size="medium"></el-checkbox>
              <span>创建时间：{{ item.CreateTime }}</span>
              <el-button
                icon="el-icon-edit"
                class="btn-item"
                @click="editName(item)"
              ></el-button>
              <el-button
                icon="el-icon-delete"
                class="btn-item"
                style="margin-left:0"
                @click="delitem(item)"
              ></el-button>
            </div>
          </div>
        </div>
        <div class="page-foot">
          <el-button
            type="primary"
            icon="el-icon-delete"
            class="delbtn"
            @click="delGroup"
            >批量删除</el-button
          >
          <TableFoot
            :pagination="pageObj"
            @changePage="changePage"
            @changeSize="changeSize"
          />
        </div>
      </div>
    </div>
    <EditTextCop ref="editTextRef" @onConfirm="searchAction"/>
  </div>
</template>

<script>
import { nextTick, onMounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import TableFoot from "@/components/TableFoot.vue";
import tools from "@/assets/js/tools";
import {GetMaterialList,UpdateImageOrVideo,disable,} from "@/helper/material.js";
import EditTextCop from "@/components/MaterialManager/EditTextCop.vue";
export default {
  name: "MiniCop",
  components: {
    TableFoot,EditTextCop
  },
  setup() {
    const router = useRouter();
    const TabList = ref([]);
    const editTextRef = ref(null)
    const pageObj = reactive({
      TotalRow: 0,
      PageIndex: 1,
      PageSize: 10,
    });
    onMounted(() => {
      searchAction();
    });

    function searchAction() {
      let param = {
        Type: 2013,
        PageNum: pageObj.PageIndex,
        PageSize: pageObj.PageSize,
      };
      GetMaterialList(param).then((res) => {
        TabList.value = res.List;
        pageObj.TotalRow = res.TotalCount;
        TabList.value.forEach((item) => {
          item.checked = false;
          item.CreateTime = tools.GMTToStr(item.CreateTime);
        });
      });
    }

    //删除
    function delitem(item) {
      ElMessageBox.confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          disable({ Mid: item.ID }).then((res) => {
            ElMessage({
              type: "success",
              message: "删除成功!",
            });
            searchAction();
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "已取消",
          });
        });
    }
    //批量删除
    function delGroup() {
      let materialCatalogId = [];
      for (let item of TabList.value) {
        if (item.checked) {
          materialCatalogId.push(item.ID);
        }
      }
      let id = materialCatalogId.join(",");
      if (materialCatalogId && materialCatalogId.length > 0) {
        ElMessageBox.confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            disable({ Mid: id }).then((res) => {
              ElMessage({
                type: "success",
                message: "删除成功!",
              });
              searchAction();
            });
          })
          .catch(() => {
            ElMessage({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        ElMessage({
          type: "warning",
          message: "请先选择要删除的内容!",
        });
      }
    }

    function editName(item) {
      editTextRef.value.initCop(item)
    }

    /****************分页********************/
    //页码改变
    function changePage(index) {
      pageObj.PageIndex = index;
      searchAction();
    }
    function changeSize(size) {
      pageObj.PageSize = size;
      pageObj.PageIndex = 1;
      searchAction();
    }

    return {
      changePage,
      changeSize,
      pageObj,
      TabList,
      delGroup,
      delitem,
      delGroup,
      editName,
      editTextRef,
      searchAction
    };
  },
};
</script>
<style lang='scss' scoped>
.table-box {
  position: relative;
}

.delbtn {
  width: 105px;
  height: 34px;
  margin: 0 10px;
  font-size: 14px;
}

.edit-list {
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  justify-content: space-around;
  border-top: 1px solid #ccc;
  box-sizing: border-box;
  padding: 0 0 0 5px;
}
.list {
  width: 100%;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 220px);
  margin-bottom: 10px;
  box-sizing: border-box;
  .content-item {
    width: 200px;
    border: 1px solid #ccc;
    margin: 20px 10px;
    .mini-header{
      width: 100%;
      display: flex;
      align-items: center;
      margin-left: 6px;
    }
    .mini_btn{
      width: 20px;
      height: 20px;
    }
    .title {
      width: 100%;
      font-size: 14px;
      margin-left: 6px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $color-common;
    }
    .msg_content{
      width: 100%;
      font-size: 14px;
      padding:0 6px;
      margin-bottom: 5px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      box-sizing: border-box;
    }
    .imgs {
      width: 188px;
      height: 150px;
      display: block;
      margin: 0 auto;
    }
    .video {
      width: 188px;
      height: 150px;
      display: block;
      margin: 0 auto;
      // object-fit:cover;
    }
  }
}
.btn-item {
  outline: none;
  border: none;
  font-size: 16px;
  padding: 0;
}
.btn-item:hover,
.btn-item:focus {
  color: #000;
  background-color: transparent;
}
.btn-item:active {
  color: #000;
}

.page-foot {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.cell-desc {
  width: 100%;
  height: 14px;
  font-size: 12px;
  color: #ccc;
  padding: 0 5px;
  margin-bottom: 5px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.name {
  width: 100%;
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  padding: 5px 0;
  font-size: 10px;
}
</style>