<template>
  <div>
    <div class="table-box">
      <div class="card-box">
            <div class="list">
              <div
                class="content-item"
                v-for="(item, index) in TabList"
                :key="index"
              >
              <p class="name">【{{item.ID}}】{{item.Remark}}</p>
                <img class="imgs" :src="item.MsgContent" />
                <div class="edit-list">
                  <el-checkbox
                    v-model="item.checked"
                    size="medium"
                  ></el-checkbox>
                  <span>创建时间：{{ item.CreateTime }}</span>
                  <el-button
                    icon="el-icon-edit"
                    class="btn-item"
                    @click="editName(item)"
                  ></el-button>
                  <el-button
                    icon="el-icon-delete"
                    class="btn-item"
                    style="margin-left:0"
                    @click="delitem(item)"
                  ></el-button>
                </div>
              </div>
            </div>
            <div class="page-foot">
              <el-button
                type="primary"
                icon="el-icon-delete"
                class="delbtn"
                @click="delGroup"
                >批量删除</el-button
              >
              <TableFoot
                :pagination="pageObj"
                @changePage="changePage"
                @changeSize="changeSize"
              />
            </div>
      </div>
    </div>
    <EditTextCop ref="editTextRef" @onConfirm="searchAction"/>
  </div>
</template>

<script>
import { nextTick, onMounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import TableFoot from "@/components/TableFoot.vue";
import EditTextCop from "@/components/MaterialManager/EditTextCop.vue";
import tools from "@/assets/js/tools";
import {GetMaterialList,UpdateImageOrVideo,disable} from "@/helper/material.js";
export default {
  name: "MaterialLib",
  components: {
    TableFoot,EditTextCop
  },
  setup() {
    const router = useRouter();
    const TabList=ref([])
    const pageObj = reactive({
      TotalRow: 0,
      PageIndex: 1,
      PageSize: 10,
    });

    const editTextRef = ref(null)

    onMounted(() => {
        searchAction()
    });

    function initCop() {
        searchAction()
    }
    function searchAction() {
        let  param = {
          Type: 2002,
          PageNum: pageObj.PageIndex,
          PageSize: pageObj.PageSize,
        };
        GetMaterialList(param).then(res=>{
            TabList.value=res.List
            pageObj.TotalRow = res.TotalCount;
            TabList.value.forEach((item) => {
                item.checked=false
                item.CreateTime = tools.GMTToStr(item.CreateTime);
            });
        })
    }

    function editName(item) {
        editTextRef.value.initCop(item)
    }
    //删除
    function delitem(item) {
      ElMessageBox.confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          disable({ Mid: item.ID }).then((res) => {
            ElMessage({
              type: "success",
              message: "删除成功!",
            });
            searchAction()
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "已取消",
          });
        });
    }
    //批量删除
    function delGroup() {
      let materialCatalogId = [];
     for (let item of TabList.value) {
        if (item.checked) {
          materialCatalogId.push(item.ID);
        }
      }
      let id = materialCatalogId.join(",");
      if (materialCatalogId&&materialCatalogId.length > 0) {
        ElMessageBox.confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
            disable({ Mid: id }).then((res) => {
                ElMessage({
                    type: "success",
                    message: "删除成功!",
                });
              searchAction()
            });
          })
          .catch(() => {
            ElMessage({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        ElMessage({
          type: "warning",
          message: "请先选择要删除的内容!",
        });
      }
    }

    /****************分页********************/
    //页码改变
    function changePage(index) {
      pageObj.PageIndex = index;
      searchAction()
    }
    function changeSize(size) {
      pageObj.PageSize = size;
      pageObj.PageIndex = 1;
      searchAction()
    }

    return {
        changePage,
        changeSize,
        pageObj,
        TabList,
        delGroup,
        editName,
        delitem,
        delGroup,
        initCop,
        editTextRef,
        searchAction
    };
  },
};
</script>
<style lang='scss' scoped>
.delbtn {
  width: 105px;
  height: 34px;
  margin: 0 10px;
  font-size: 14px;
}
.addbtn {
  font-size: 14px;
    position: absolute;
    top: 0;
    right: 30px;
}
.edit-list {
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  justify-content: space-around;
  border-top: 1px solid #ccc;
  box-sizing: border-box;
  padding: 0 0 0 5px;
}
.list {
  width: 100%;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 220px);
  margin-bottom: 10px;
  box-sizing: border-box;
  .content-item {
    width: 200px;
    border: 1px solid #ccc;
    margin: 20px 10px;
    .title{
      width: 100%;
      font-size: 14px;
      overflow:hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .imgs {
      width: 188px;
      height: 150px;
      display: block;
      margin: 0 auto;
    }
    .video {
      width: 188px;
      height: 150px;
      display: block;
      margin: 0 auto;
      // object-fit:cover;
    }
  }
}
.btn-item {
  outline: none;
  border: none;
  font-size: 16px;
  padding: 0;
}
.btn-item:hover,
.btn-item:focus {
  color: #000;
  background-color: transparent;
}
.btn-item:active {
  color: #000;
}

.page-foot {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.name {
  width: 95%;
  background: #fff;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  padding: 5px 0;
  font-size: 13px;
}
</style>