<template>
  <div>
    <el-dialog title="编辑素材" v-model="dialogVisible" width="600px">
       <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleFormRef"
        label-width="100px"
        class="ruleForm"
      >
      <el-form-item label="编号:" prop="data.Id">
        <div style="display:flex">
          <el-input
            v-model="ruleForm.data.Id"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="margin-right:5px"
            placeholder="请填写编号"
            :disabled="true"
            maxlength="5"
            show-word-limit
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="素材名称:" prop="data.MaterialName">
          <el-input
            v-model="ruleForm.data.MaterialName"
            ref="inputRef"
            placeholder="请填写素材名称"
            maxlength="20"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="视频封面:" prop="data.VideoUrl" v-if="videPage">
            <img :src="ruleForm.data.VideoUrl" class="box-img" />
        </el-form-item>
        <el-form-item label="上次封面:" v-if="videPage">
          <el-upload
            class="avatar-uploader"
            action="#"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :http-request="uploadFile"
            accept="image/jpeg,image/jpg,image/png,image/gif"
          >
            <el-button type="primary">上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitAction('ruleForm')">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { onMounted, reactive, ref, unref } from "vue";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import tools from "@/assets/js/tools";
import {UpLoadImageOrVideo,UpdateImageOrVideo } from "@/helper/material.js";
import _ from "lodash";

export default {
  name: "EditTextCop",
  components: {
  },
  setup(prop, context) {
    const dialogVisible=ref(false)
    const ruleFormRef = ref(null);
    const ruleForm = reactive({
      data: {
        MaterialName: "",
        VideoUrl:""
      },
    });
    const rules = reactive({
      data: {}
    });

    onMounted(() => {});

    const videPage = ref(false)

    function initCop(item,video = false){
        dialogVisible.value = true
        videPage.value = video
        resetData()
        loadData(item)
    }
    
    function resetData(){
        ruleForm.data.MaterialName = ''
        ruleForm.data.VideoUrl = ''
    }

    function loadData(item){    
        ruleForm.data.MaterialName = item.Remark
        ruleForm.data.Id = item.ID
        ruleForm.data.VideoUrl = item.MsgContent
    }

    function beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 4;
      if (!isLt2M) {
        ElMessage({
          type: "error",
          message: "上传图片大小不能超过 4M!",
        });
      }
      return  isLt2M;
    }

    function uploadFile(file) {
      const formData = new FormData();
      formData.append("file", file.file);
        UpLoadImageOrVideo(2002,formData).then((res) => {
          ruleForm.data.VideoUrl = res.OSSUrl;
          ElMessage.success({
            message: "上传成功",
            type: "success",
          });
        });
    }
    /*********************************点击事件******************************************/
     //确定表单
    const submitAction = _.debounce(
      async () => {
        const form = unref(ruleFormRef);
        if (!form) return;
        try {
            await form.validate();
            let param = {
                Mid: ruleForm.data.Id,
                Remark: ruleForm.data.MaterialName
            };
            if(videPage.value){
                param.Cover = ruleForm.data.VideoUrl
            }
            console.log(param);
            UpdateImageOrVideo(param).then((res) => {
                ElMessage({
                    type: "success",
                    message: "提交成功",
                });
                dialogVisible.value = false;
                context.emit("onConfirm");
            });
        } catch (error) {
          console.log(error);
        }
      },
      1000,
      {
        leading: true, //在延迟开始前立即调用事件
        trailing: false, //在延时结束后不调用,保证事件只被触发一次
      }
    );


    return {
        dialogVisible,
        initCop,
        ruleForm,
        rules,
        submitAction,
        ruleFormRef,
        videPage,
        uploadFile,
        beforeUpload
    };
  },
};
</script>
<style lang='scss' scoped>
.ruleForm {
  // width: 400px;
  margin: 20px 20px 0;
  p {
    font-size: 12px;
    color: #bbb;
  }
   .box-img {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 10px;
  }
}
</style> 
