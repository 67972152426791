<template>
  <div>
    <div class="table-box" >
      <div class="card-box">
        <el-tabs v-model="tabIndex" @tab-click="handleClick">
          <el-tab-pane label="图片管理" name="first" >
              <PicCop ref="picRef" v-if="tabIndex == 'first'"/>
          </el-tab-pane>
          <el-tab-pane label="链接管理" name="second">
              <LinkCop ref="linkRef" v-if="tabIndex == 'second'"/>
          </el-tab-pane>
          <el-tab-pane label="小程序管理" name="third">
              <MiniCop v-if="tabIndex == 'third'"/>
          </el-tab-pane>
          <el-tab-pane label="视频管理" name="fouth">
            <VideoCop ref="videoRef" v-if="tabIndex == 'fouth'"/>
          </el-tab-pane>
          <el-tab-pane label="视频号管理" name="fifth">
             <VideoNumCop v-if="tabIndex=='fifth'"/> 
          </el-tab-pane>
        </el-tabs>
      </div>
      <div>
        <el-upload
          class="upload-demo"
          action="#"
          :before-upload="beforeUpload"
          :http-request="uploadFile1"
          :show-file-list="false"
          accept="image/jpeg,image/jpg,image/png,image/gif"
        >
          <el-button type="primary" icon="el-icon-picture" class="addbtn" v-if="tabIndex == 'first'">添加图片</el-button>
        </el-upload>
        <el-button type="primary" icon="el-icon-plus" class="addbtn" @click="addurlBtn" v-if="tabIndex == 'second'">添加链接</el-button>
        <el-upload
          class="upload-demo"
          action="#"
          accept="video/mp4,video/flv,video/avi,video/wmv,video/rmvb,video/mov"
          :before-upload="beforeUploadVideo"
          :show-file-list="false"
          :http-request="uploadFile2"
        >
          <el-button type="primary" icon="el-icon-video-camera-solid" class="addbtn" v-if="tabIndex == 'fouth'">添加视频</el-button>
        </el-upload>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick, onMounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import PicCop from "@/components/MaterialManager/PicCop"
import LinkCop from "@/components/MaterialManager/LinkCop"
import MiniCop from "@/components/MaterialManager/MiniCop"
import VideoCop from "@/components/MaterialManager/VideoCop"
import VideoNumCop from "@/components/MaterialManager/VideoNum"
import {CreateImageOrVideo} from "@/helper/material.js";
import tools from "@/assets/js/tools";

export default {
  name: "MaterialLib",
  components: {
      PicCop,
      LinkCop,
      MiniCop,
      VideoCop,
      VideoNumCop
  },
  setup() {
    const tabIndex=ref("first")
    const picRef=ref(null)
    const linkRef=ref(null)
    const videoRef=ref(null)
    onMounted(() => {
      handleClick({ props: { name: "first" } });
    });

    function handleClick(tab) {
      if (tab.props.name == "first") {
        tabIndex.value = 'first'
      } else if (tab.props.name == "second") {
        tabIndex.value = 'second'
      } else if (tab.props.name == "third") {
        tabIndex.value = 'third'
      } else if (tab.props.name == "fouth") {
        tabIndex.value = 'fouth'
      }else if (tab.props.name == "fifth") {
        tabIndex.value = 'fifth'
      }
    }

     //上传
    function beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 4;
      if (!isLt2M) {
        ElMessage({
          type: "error",
          message: "上传图片大小不能超过 4M!",
        });
      }
      return  isLt2M;
    }

    function beforeUploadVideo(file) {
      let types = [
        "video/mp4",
        "video/flv",
        "video/avi",
        "video/wmv",
        "video/rmvb",
        "video/mov",
      ];
      const isVideo = types.includes(file.type);
      const isLt2M = file.size / 1024 / 1024 < 50;

      if (!isVideo) {
        ElMessage({
          type: "error",
          message: "请上传正确的视频格式",
        });
      }
      if (!isLt2M) {
        ElMessage({
          type: "error",
          message: "上传视频大小不能超过 50MB!",
        });
      }
      return isVideo && isLt2M;
    }

     function uploadFile1(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      CreateImageOrVideo({ Type: 2002, formData }).then((res) => {
        picRef.value.initCop()
      });
    }

    function uploadFile2(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      CreateImageOrVideo({ Type: 2004, formData }).then((res) => {
        videoRef.value.initCop()
      });
    }

    //添加链接
    function addurlBtn(){
        linkRef.value.initCop()
    }
    return {
        tabIndex,
        handleClick,
        beforeUpload,
        uploadFile1,
        picRef,
        linkRef,
        addurlBtn,
        videoRef,
        uploadFile2,
        beforeUploadVideo
    };
  },
};
</script>
<style lang='scss' scoped>
:deep().card-box .el-tabs--card > .el-tabs__header {
  margin: 0;
}
:deep().card-box .el-tabs__nav-wrap {
  margin: 0;
}
:deep().card-box .el-tabs__item.is-active {
  border-bottom: none;
  color: none;
}
:deep().card-box .el-tabs__item {
  width: 150px;
  text-align: center;
  // background: #ccc;
}
:deep().name > .el-input__inner {
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  padding: 0 5px;
}
:deep().el-divider--horizontal {
  margin: 0;
}
:deep().card-box .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #bfb18a;
  border-color: #bfb18a;
}
:deep().card-box .el-checkbox__inner:hover {
  border-color: #bfb18a;
}
:deep().card-box .el-tabs__item:hover {
  color: #bfb18a;
}
:deep().card-box .el-tabs__item.is-active {
  color: #bfb18a;
}

:deep() .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: $color-common;
  color: #fff;
}
:deep().el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon {
  display: inline-block !important;
}
:deep().el-pagination .btn-prev .el-icon,
.el-pagination .btn-prev .el-icon {
  display: inline-block !important;
}

:deep().el-pager li:hover {
  color: $color-common !important;
}
:deep().mini-item .el-input__inner{
  background:rgba(0,0,0,.5);
  text-align:center;
  color: #fff;
}
:deep().el-tabs__active-bar{
  background-color: $color-common;
}
.addbtn {
  font-size: 14px;
    position: absolute;
    top: 5px;
    right: 30px;
}
.table-box{
    position: relative;
}
</style>