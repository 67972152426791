<template>
  <div>
    <div class="table-box">
      <div class="card-box">
        <div class="link-list">
              <div
                class="link-item"
                v-for="(item, index) in TabList"
                :key="index"
              >
                <div class="link-content">
                  <div class="link-desc">
                    <div class="title">【{{item.ID}}】{{ item.MsgTitle }}</div>
                    <div class="desc">{{ item.MsgDesc }}</div>
                  </div>
                  <div class="link-img">
                    <img :src="item.MsgContent" />
                  </div>
                </div>
                <div class="edit-list">
                  <el-checkbox
                    v-model="item.checked"
                    size="medium"
                  ></el-checkbox>
                  <span>创建时间：{{ item.CreateTime }}</span>
                  <el-button
                    icon="el-icon-edit"
                    class="btn-item"
                    @click="editName(item)"
                  ></el-button>
                  <el-button
                    icon="el-icon-delete"
                    class="btn-item"
                    style="margin-left:0"
                    @click="delitem(item)"
                  ></el-button>
                </div>
              </div>
            </div>
            <div class="page-foot">
              <el-button
                type="primary"
                icon="el-icon-delete"
                class="delbtn"
                @click="delGroup"
                >批量删除</el-button
              >
              <TableFoot
                :pagination="pageObj"
                @changePage="changePage"
                @changeSize="changeSize"
              />
            </div>
      </div>
    </div>
    <el-dialog title="添加链接" width="600px" v-model="dialogVisible" center>
      <CreateLinkCop ref="createLinkRef" @submit="submitAction" @cancel="dialogVisible = false" />
    </el-dialog>
    <EditLinkCop ref="editLinkRef" @onConfirm="searchAction"/>
  </div>
</template>

<script>
import { nextTick, onMounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import TableFoot from "@/components/TableFoot.vue";
import tools from "@/assets/js/tools";
import {GetMaterialList,disable,CreateImageOrVideo} from "@/helper/material.js";
import CreateLinkCop from "@/components/GroupMsg/CreateLinkCop";
import EditLinkCop from "@/components/MaterialManager/EditLinkCop";

export default {
  name: "LinkCop",
  components: {
    TableFoot,CreateLinkCop,EditLinkCop
  },
  setup() {
    const router = useRouter();
    const dialogVisible = ref(false);
    const createLinkRef=ref(null);
    const TabList=ref([])
    const pageObj = reactive({
      TotalRow: 0,
      PageIndex: 1,
      PageSize: 10,
    });
    onMounted(() => {
        searchAction()
    });

    function initCop() {
        dialogVisible.value=true
        if(createLinkRef.value){
            createLinkRef.value.resetAction()
        }
    }

    function searchAction() {
        let  param = {
           Type: 2005,
          PageNum: pageObj.PageIndex,
          PageSize: pageObj.PageSize,
        };
        GetMaterialList(param).then(res=>{
            TabList.value=res.List
            pageObj.TotalRow = res.TotalCount;
            TabList.value.forEach((item) => {
                item.checked=false
                item.CreateTime = tools.GMTToStr(item.CreateTime);
            });
        })
    }

    //删除
    function delitem(item) {
      ElMessageBox.confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          disable({ Mid: item.ID }).then((res) => {
            ElMessage({
              type: "success",
              message: "删除成功!",
            });
            searchAction()
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "已取消",
          });
        });
    }
    //批量删除
    function delGroup() {
      let materialCatalogId = [];
     for (let item of TabList.value) {
        if (item.checked) {
          materialCatalogId.push(item.ID);
        }
      }
      let id = materialCatalogId.join(",");
      if (materialCatalogId&&materialCatalogId.length > 0) {
        ElMessageBox.confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
            disable({ Mid: id }).then((res) => {
                ElMessage({
                    type: "success",
                    message: "删除成功!",
                });
              searchAction()
            });
          })
          .catch(() => {
            ElMessage({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        ElMessage({
          type: "warning",
          message: "请先选择要删除的内容!",
        });
      }
    }

    //提交
    function submitAction() {
      dialogVisible.value = false;
      searchAction()
    }

    const editLinkRef = ref(null)

    function editName(item){
      editLinkRef.value.initCop(item)
    }
    /****************分页********************/
    //页码改变
    function changePage(index) {
      pageObj.PageIndex = index;
      searchAction()
    }
    function changeSize(size) {
      pageObj.PageSize = size;
      pageObj.PageIndex = 1;
      searchAction()
    }

    return {
        changePage,
        changeSize,
        pageObj,
        TabList,
        delGroup,
        delitem,
        delGroup,
        initCop,
        dialogVisible,
        createLinkRef,
        editName,
        submitAction,
        editLinkRef,
        searchAction
    };
  },
};
</script>
<style lang='scss' scoped>

.table-box {
  position: relative;
}

.delbtn {
  width: 105px;
  height: 34px;
  margin: 0 10px;
  font-size: 14px;
}
.addbtn {
  font-size: 14px;
    position: fixed;
    top: 76px;
    right: 30px;
}
.edit-list {
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  justify-content: space-around;
  border-top: 1px solid #ccc;
  box-sizing: border-box;
  padding: 0 0 0 5px;
}
.link-list {
  width: 100%;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 320px);
  border-right: 1px solid $color-border;
  border-bottom: 1px solid $color-border;
  border-left: 1px solid $color-border;
  box-sizing: border-box;
  margin-bottom: 10px;

  .link-item {
    width: 300px;
    border: 1px solid #ccc;
    margin: 20px 10px;
    .link-content {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      .link-desc {
        margin: 10px 15px;
        .title {
          width: 200px;
          font-size: 14px;
          margin-bottom: 10px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
        .desc {
          font-size: 12px;
          color: #ddd;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .link-img {
        width: 60px;
        height: 60px;
        margin: 5px;
        img {
          width: 60px;
          height: 60px;
        }
      }
    }
  }
}
.btn-item {
  outline: none;
  border: none;
  font-size: 16px;
  padding: 0;
}
.btn-item:hover,
.btn-item:focus {
  color: #000;
  background-color: transparent;
}
.btn-item:active {
  color: #000;
}

.page-foot {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

</style>